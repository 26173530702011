/* eslint-disable */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as Sentry from "@sentry/vue";

//import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

//Google Analytics
import  VueGtag  from 'vue-gtag'

// Global Components
import './global-components'

// Custom directives
import './custom-directives'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
// Axios Mock Adapter
// modified axios with endpoint
import '@/axios'

// VueCropper
import VueCropper from 'vue-cropper'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { FETCH_PROFILE } from '@/store/modules/auth.module'
import Hotjar from 'vue-hotjar'
import { INIT_SHOP } from './store/modules/shop.module'
import { apiToastError } from './@core/utils/toast';
import {Settings} from "@/Settings";
import {refreshTraceId} from "@/traceIdCookie";

(async () => {

  await Settings.load();
  refreshTraceId(undefined);

  Vue.use(VueCropper)

// BSV Plugin Registration
  Vue.use(ToastPlugin)
  Vue.use(ModalPlugin)

// Composition API
  Vue.use(VueCompositionAPI)

  Vue.use(VueTelInput)
// Vue.use('loading', Loading)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
  require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
  require('@core/scss/core.scss')

// import assets styles
  require('@/assets/scss/style.scss')

  Vue.config.productionTip = false

  Vue.use(VueGtag, {
    config: {id: Settings.getConfig("VUE_APP_GOOGLE_ANALYTICS_4")},
    includes: [{
      id: Settings.getConfig("VUE_APP_UNIVERSAL_ANALYTICS")
    }]
  });

  if (Settings.getConfig("VUE_APP_HOTJAR_TOKEN") && !store.getters.isAdmin) {
    Vue.use(Hotjar, {
      id: Settings.getConfig("VUE_APP_HOTJAR_TOKEN")
    })
  }

  initializeSentry()

  function initializeSentry() {
    if (!Settings.getConfig("VUE_APP_SENTRY_DSN")) return;
    Sentry.init({
      Vue,
      dsn: Settings.getConfig("VUE_APP_SENTRY_DSN"),
      environment: Settings.getConfig("VUE_APP_ENVIRONMENT") ? Settings.getConfig("VUE_APP_ENVIRONMENT") : 'development',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [Settings.getConfig("VUE_APP_BACKEND_URL")],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.1, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  new Vue({
    router,
    store,
    //i18n,
    render: h => h(App),
    async beforeCreate() {
      // restore user session
      this.$store.commit('restoreSession')
      if (this.$store.getters.isAuthenticated && !this.$store.getters.isGuest) {
        try {
          await this.$store.dispatch(FETCH_PROFILE);
        } catch (err) {
          apiToastError(err)
        }
        if (Settings.getConfig("VUE_APP_SENTRY_DSN")) {
          const profile = this.$store.getters.profile
          const user = {
            id: profile?._id || null,
            email: profile?.email || null,
            role: profile?.entityType || null
          }
          Sentry.setUser(user)
        }
      }
      // init shop
      this.$store.commit(INIT_SHOP)
    },
    watch: {
      '$route': function () {
        this.$gtag.pageview(this.$route)
      }
    }

  })
      .$mount('#app')
})();