import { kpRequest, kpEndpoint, kpParams } from '@/axios'

// Collection Related Actions
export const GET_COLLECTION_LIST = 'getCollectionList'
export const GET_COLLECTION_BY_ID = 'getCollectionById'
export const GET_UPCOMING_DEADLINE_COLLECTIONS = 'getUpcomingDeadlineCollections'
export const CREATE_COLLECTION = 'createCollection'
export const UPDATE_COLLECTION = 'updateCollection'
export const DELTE_COLLECTION = 'deleteCollection'
export const DOWNLOAD_COLLECTION = 'downloadCollection'

// Product Related Actions
export const FETCH_COLLECTION_PRODUCTS = 'fetchCollectionProducts'
export const FETCH_COLLECTION_PRODUCT_BY_ID = 'fetchCollectionProductById'
export const FETCH_COLLECTION_PRODUCT_FILTERS = 'fetchCollectionProductFilters'
export const FETCH_DISCOVER_PRODUCTS = 'fetchDiscoverProducts'
export const DELETE_COLLECTION_PRODUCT = 'deleteCollectionProduct'
export const GET_RELATED_PRODUCTS = 'getRelatedProducts'

// Price book related actions
export const LOAD_PRICE_BOOKS = 'loadPriceBooks'
export const GET_PRICE_BOOK = 'getPriceBook'
export const IMPORT_PRICE_BOOK = 'importPriceBook'
export const CREATE_PRICE_BOOK = 'createPriceBook'
export const SET_PRICE_BOOKS = 'setPriceBooks'

// Currency book related actions
export const UPDATE_CURRENCIES = 'updateCurrencies'
export const EXPORT_CURRENCIES = 'exportCurrencies'
export const IMPORT_CURRENCIES = 'importCurrencies'

const state = {
    priceBooks: [],
}

export const mutations = {
    [SET_PRICE_BOOKS](state, priceBooks) {
        state.priceBooks = priceBooks
    },
}

export const actions = {
    // Collection Related Apis
    async [GET_COLLECTION_LIST](ctx, params = {}) {
        return await kpRequest({ ...kpEndpoint.catalog.get, params })
    },
    async [GET_COLLECTION_BY_ID](ctx, collectionId) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.getById, collectionId) })
    },
    async [GET_UPCOMING_DEADLINE_COLLECTIONS](ctx, params = {}) {
        return await kpRequest({ ...kpEndpoint.catalog.getUpcomingdeadlines, params })
    },
    async [GET_COLLECTION_LIST](ctx, params = {}) {
        return await kpRequest({ ...kpEndpoint.catalog.get, params })
    },
    async [CREATE_COLLECTION](ctx, { payload = {}, loaderObj = {} }) {
        return await kpRequest({ ...kpEndpoint.catalog.create, payload, ...loaderObj })
    },
    async [UPDATE_COLLECTION](ctx, { collectionId, payload = {}, loaderObj = {} }) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.update, collectionId), payload, ...loaderObj })
    },
    async [DELTE_COLLECTION](ctx, { collectionId, params = {} }) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.delete, collectionId), params })
    },

    // Product Related Apis
    async [FETCH_COLLECTION_PRODUCTS](ctx, params = {}) {
        return await kpRequest({ ...kpEndpoint.product.list, params })
    },
    async [FETCH_COLLECTION_PRODUCT_BY_ID](ctx, { productId, params = {} }) {
      return await kpRequest({ ...kpParams(kpEndpoint.product.getById, productId), params })
    },
    async [FETCH_COLLECTION_PRODUCT_FILTERS](ctx, params = {}) {
      return await kpRequest({ ...kpEndpoint.product.filter, params })
    },
    async [FETCH_DISCOVER_PRODUCTS](ctx, params = {}) {
      return await kpRequest({ ...kpEndpoint.product.browse, params })
    },
    async [DELETE_COLLECTION_PRODUCT](ctx, { productId, params = {} }) {
       return await kpRequest({ ...kpParams(kpEndpoint.product.deleteProduct, productId), params })
    },
    async [GET_RELATED_PRODUCTS](ctx, { productId, params = {} }) {
        return await kpRequest({ ...kpParams(kpEndpoint.product.relatedProducts, productId), params })
    },
    async [DOWNLOAD_COLLECTION](ctx, { payload = {}, params }) {
        return await kpRequest({ ...kpEndpoint.downloads.collection, payload, params })
    },

    // Price books related apis
    async [LOAD_PRICE_BOOKS](ctx) {
        await kpRequest({ ...kpParams(kpEndpoint.catalog.pricebook.get) }).then(res => {
            ctx.commit(SET_PRICE_BOOKS, res?.data?.data || [])
          }).catch(err => {
            console.error(err)
          })
    },
    async [GET_PRICE_BOOK](ctx, collectionId = null) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.pricebook.getByCollectionId, collectionId) })
    },
    async [CREATE_PRICE_BOOK](ctx, { payload = {}, collectionId = null }) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.pricebook.create, collectionId), payload })
    },
    async [IMPORT_PRICE_BOOK](ctx, { payload = {}, collectionId = null, priceBookId = null }) {
        return await kpRequest({ ...kpParams(priceBookId ? kpEndpoint.catalog.pricebook.import : kpEndpoint.catalog.pricebook.importMasterFile, collectionId, priceBookId), payload })
    },

    // Currency books related apis
    async [UPDATE_CURRENCIES](ctx, { collectionId = null, payload = {} }) {
        return await kpRequest({ ...kpParams(kpEndpoint.catalog.currencybook.updateCurrencies, collectionId), payload })
    },
    async [EXPORT_CURRENCIES](ctx, { collectionId = null, currency = null }) {
        let requestUrl = ''
        if (currency) {
            requestUrl = kpParams(kpEndpoint.catalog.currencybook.exportCurrencies, collectionId, currency)
        } else {
            requestUrl = kpParams(kpEndpoint.catalog.currencybook.exportMasterCurrencies, collectionId)
        }
        return requestUrl
    },
    async [IMPORT_CURRENCIES](ctx, { params = {}, payload = {} }) {
        let requestUrl = ''
        if (params?.currency) {
            requestUrl = kpParams(kpEndpoint.catalog.currencybook.importCurrencies, params.collectionId, params.currency)
        } else {
            requestUrl = kpParams(kpEndpoint.catalog.currencybook.importMasterCurrencies, params.collectionId)
        }
        return await kpRequest({ ...requestUrl, payload })
    },
}

export default {
    actions,
    state,
    mutations,
}
