import store from '@/store/index'
import { LOGOUT } from '@/store/modules/auth.module'

export const AUTH_REGISTER = 'auth-register'
export const AUTH_REGISTER_CHILDREN = {
  CHOOSE_ROLE: 'choose-role',
  ADD_USER: 'add-user',
  ENTER_DETAILS: 'enter-details',
  SET_PASSWORD: 'set-password',
}

const AUTH_REGISTER_META = {
  layout: 'full',
  resource: 'Auth',
  redirectIfLoggedIn: true,
  hideForAuth: true,
}

const authGuardSignupV2 = (to, from, next) => {
  const signupDetails = store.getters.getSignupDetails
  if (!(signupDetails && !!signupDetails.role)) {
    next({
      name: AUTH_REGISTER_CHILDREN.CHOOSE_ROLE,
      replace: true,
    })
  } else {
    next()
  }
}

// Routes array starts here...
export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/apps/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/apps/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      hideForAuth: true,
    },
  },
  {
    path: '/register',
    name: AUTH_REGISTER,
    component: () => import('@/views/apps/authentication/signup/Signup.vue'),
    children: [
      {
        path: '',
        name: AUTH_REGISTER,
        redirect: { name: 'choose-role' },
        meta: AUTH_REGISTER_META,
      },
      {
        path: 'choose-role',
        name: AUTH_REGISTER_CHILDREN.CHOOSE_ROLE,
        component: () => import('@/views/apps/authentication/signup/ChooseRole.vue'),
        meta: AUTH_REGISTER_META,
      },
      {
        path: 'add-user',
        name: AUTH_REGISTER_CHILDREN.ADD_USER,
        component: () => import('@/views/apps/authentication/signup/AddUser.vue'),
        meta: AUTH_REGISTER_META,
        beforeEnter: authGuardSignupV2,
      },
      {
        path: 'enter-details',
        name: AUTH_REGISTER_CHILDREN.ENTER_DETAILS,
        component: () => import('@/views/apps/authentication/signup/EnterDetails.vue'),
        meta: AUTH_REGISTER_META,
        beforeEnter: authGuardSignupV2,
      },
      {
        path: 'set-password',
        name: AUTH_REGISTER_CHILDREN.SET_PASSWORD,
        component: () => import('@/views/apps/authentication/signup/SetPassword.vue'),
        meta: AUTH_REGISTER_META,
        beforeEnter: authGuardSignupV2,
      },
    ],
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      hideForAuth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/apps/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      hideForAuth: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/apps/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      hideForAuth: true,
    },
  },
  // created change-password router
  {
    path: '/change-password/:token',
    name: 'auth-change-password-v2',
    component: () => import('@/views/apps/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      hideForAuth: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter(to, from, next) {
        const destination = {
          path: from.path || '/',
          query: from.query,
          params: from.params,
        }
        store.dispatch(LOGOUT)
          .then(() => next(destination))
      },
    },
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/apps/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/apps/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/apps/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/apps/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/apps/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/apps/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/guest-user',
    name: 'guest-user',
    component: () => import('@/views/apps/authentication/GuestLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      hideForAuth: true,
      redirectIfLoggedIn: true,
    },
  },
]
