export const SET_IS_ENABLE_CREATE_WISHLIST = 'setIsEnableCreateWishlist'

export const state = {
    isEnableCreateWishlist: false,
}

export const mutations = {
    [SET_IS_ENABLE_CREATE_WISHLIST](state, payload) {
        state.isEnableCreateWishlist = payload
    },
}

export default {
    state,
    mutations,
}
